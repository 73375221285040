import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Mini Project´s Portal</h1>
        <a href="https://adduser.danielbjork.se">Add User</a>
        <a href="https://addusermodal.danielbjork.se">Add User Modal</a>
        <a href="https://adduserreducer.danielbjork.se">Add User Reducer</a>
        <a href="https://concepts.danielbjork.se">Concepts</a>
        <a href="https://expensereport.danielbjork.se">Expense Report</a>
        <a href="https://ranta-pa-ranta.danielbjork.se">
          Investment Calculator
        </a>
        <a href="https://simplelogin.danielbjork.se">Simple Login</a>
      </header>
    </div>
  );
}

export default App;
